import { signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../firebase';

const Header = () => {
  const [user] = useAuthState(auth);
  const [userDropdown, setUserDropdown] = useState(false);

  return (
    <div className="h-12 w-full bg-slate-400 flex">
      <div className="w-2/3 flex justify-start items-center">
        <div className="m-2">レシート家計簿</div>
      </div>
      <div className="w-1/3 flex justify-end items-center">
        <div className="m-2">
          {user ? (
            <div>
              <img
                src={user.photoURL}
                alt=""
                className="h-8 w-8 rounded-full cursor-pointer z-20"
                onClick={() => {
                  setUserDropdown(!userDropdown);
                }}
              />
              {userDropdown ? (
                <div>
                  <div className="relative">
                    <div className="absolute right-0 z-10 w-56 mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
                      <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                        <div>{user.displayName}</div>
                        <div className="font-medium truncate">{user.email}</div>
                      </div>
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownInformationButton"
                      >
                        {/* <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Dashboard
                          </a>
                        </li> */}
                      </ul>
                      <div className="py-2">
                        <button
                          onClick={() => signOut(auth)}
                          className="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        >
                          Sign out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* onClick={() => signOut(auth)}  */}
    </div>
  );
};

export default Header;
